body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Condensed", sans-serif;
}
:root {
  --myOrange: #fe5000;
  --myGreen: #7dbe16;
  --myBlack: #1a1a1a;
  --myWhite: #ffffff;
}
