.contenedor-general-carrousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contenedor-carrousel {
  width: 100%;
}
.contenedor-imagenes {
  width: 100%;
  max-height: max-content;
  height: 351px;
  text-align: center;
  justify-content: center;
}

.imagen-principal {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  opacity: 50%;
}
.texto-imagen {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  font-weight: 600;

  animation-name: sacarTexto;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;

  animation-play-state: running;
  text-shadow: 0px 10px 10px rgb(229, 235, 247, 0.25);
}

.texto-imagen::after {
  font-size: 48px;
  font-weight: 600;
  transition-property: font-size;
  transition-duration: 3s;
  transition-delay: 2s;
}

.texto-Home {
  width: 85%;
  text-align: center;
  margin-top: 50px;
}
.texto-Home h1 {
  font-size: 30px;
}
.contenedor-proyectos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}
.contenedor-proyectos-reformas-descripcion,
.contenedor-proyectos-casas-descripcion {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
}
.contenedor-reformas,
.contenedor-steelframe {
  width: 50%;
  margin: 30px;
}

.contenedor-proyectos-reformas-textos {
  margin-right: 30px;
  display: block;
  width: 40%;
  text-align: justify;
}
.contenedor-proyectos-casas-textos {
  margin-left: 30px;
  display: block;
  width: 40%;
  text-align: justify;
}

.reformas-home,
.steelframe-home {
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

@keyframes transicionImagenes {
  from {
    left: 0px;
  }
  to {
    left: 100px;
  }
}
/*mobile Portrait */
@media only screen and (max-device-width: 370px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .texto-imagen {
    top: 52%;
    font-size: 40px;
  }
  .texto-Home h1 {
    font-size: 22px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .contenedor-proyectos {
    display: block;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 150px;
    margin-bottom: 30px;
  }
  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 428px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .texto-imagen {
    top: 52%;
    font-size: 40px;
  }
  .texto-Home h1 {
    font-size: 22px;
  }

  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 150px;
    margin-bottom: 30px;
  }

  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}

/*mobile Landscape */
@media only screen and (max-device-width: 926px) and (max-device-height: 428px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .contenedor-imagenes,
  .contenedor-proyectos {
    width: 100%;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
  }

  .texto-imagen {
    top: 75%;
    font-size: 30px;
  }
  .texto-Home h1 {
    font-size: 22px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .reformas-home,
  .steelframe-home {
    margin-left: 10px;
    margin-right: 40px;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
    margin-left: 60px;
  }
}
