body,
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.HolyGrail-body {
  display: flex;
  flex: 1;
}
.HolyGrail-content {
  flex: 1;
}
.cabeceraSuperior,
footer {
  display: flex;
  color: var(--myOrange);
  justify-content: space-between;
  align-items: center;
  background-color: var(--myBlack);
}

.HolyGrail-nav,
.HolyGrail-ads {
  /* 12em is the width of the columns */
  flex: 0 0 12em;
  background-color: var(--myWhite);
  color: var(--myBlack);
}

.HolyGrail-nav {
  /* put the nav on the left 
  order: -1;*/
  display: flex;
  justify-items: center;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.Logotipo {
  width: 22%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
img {
  height: max-content;
  max-width: -webkit-fill-available;
}
.Navegador-home {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-around;
  list-style: none;
}

.Navegador-home a {
  text-decoration: none;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  color: var(--myBlack);
  text-transform: uppercase;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Navegador-home a:hover {
  color: var(--myOrange);
}

.Navegador-home a:after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  transition: all 0.7s ease;
  bottom: 5%;
}
.Navegador-home a:hover::after {
  width: 100%;
  height: 2px;
  background-color: var(--myOrange);
}

.HolyGrail,
.HolyGrail-body {
  display: flex;
  flex-direction: column;
}

.HolyGrail-nav {
  order: -1;
}

@media (min-width: 768px) {
  .HolyGrail-body {
    flex-direction: row;
    flex: 1;
  }
  .HolyGrail-content {
    flex: 1;
  }
  .HolyGrail-nav,
  .HolyGrail-ads {
    /* 12em is the width of the columns */
    flex: 0 0 12em;
  }
}

@media only screen and (max-device-width: 370px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .cabeceraSuperior,
  footer {
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .HolyGrail-nav {
    flex-flow: wrap;
    height: 50%;
  }
  .Logotipo {
    height: 50px;
    margin-top: 10px;
  }
  .Logotipo img {
    height: 50px;
  }

  .Navegador-home {
    width: 100%;
  }
  .Navegador-home a {
    font-size: 14px;
  }
  ul.Navegador-home {
    padding: 0;
    margin-top: 15px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 428px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .cabeceraSuperior,
  footer {
    flex-flow: wrap;
    justify-content: flex-start;
  }
  .HolyGrail-nav {
    flex-flow: wrap;
    height: 50%;
  }
  .Logotipo {
    height: 50px;
    margin-top: 10px;
  }
  .Logotipo img {
    height: 50px;
  }

  .Navegador-home {
    width: 100%;
  }
  .Navegador-home a {
    font-size: 16px;
  }
  ul.Navegador-home {
    padding: 0;
    margin-top: 15px;
  }
}
/*mobile Landscape */
@media only screen and (max-device-width: 926px) and (max-device-height: 428px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .Logotipo {
    height: 50px;
  }
  .Logotipo img {
    height: 50px;
  }
  .Navegador-home {
    justify-content: space-evenly;
  }
  .Navegador-home a {
    font-size: 16px;
  }
  ul.Navegador-home {
    justify-content: space-around;
    padding: 0px;
  }
}

@media only screen and (min-device-width: 428px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .Logotipo {
    height: 60px;
    margin-top: 15px;
  }
  .Logotipo img {
    height: 60px;
  }

  .Navegador-home a {
    font-size: 24px;
  }
}
